import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { isIOS } from 'react-device-detect'
import _ from 'lodash'
import Dropzone from 'react-dropzone'
import HelperModelForPreview from '../gallery/HelperModelForPreview'
import { getCloudFrontAssetsUrl } from '../../utils/assets'
import classNames from 'classnames'
import MediaCategory from './MediaCategory'
import { getAllCategory } from './../../actions/mediaAction'

const DropZoneSection = styled.section`
    width: 100%;
    height: fit-content;
    border: solid;
    border-width: 2px;
    border-radius: 5px;
    padding: 12px;
    border-color: ${props => props.site_font_color};
`

const ColumnDiv = styled.div`
    width: 100%;
    margin-bottom: ${props => props.isUploading === true ? '50px' : '20px'};
    
    .positioning {
        display: none !important;
    }

    .form-group {
        position: absolute;
        bottom: -24px;
    }

    .progress-span {
        position: absolute;
        bottom: -44px;
        width: 88%;
    }

    .progress {
        border-radius: 5px;
    }

    .progress-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    @media(max-width: 767px) {
        .positioning {
            display: inline-block !important;
            width: 20%;
        }

        .progress {
            width: 82%;
            display: inline-block !important;
            border-radius: 5px;
        }

        .positioning i {
            color: ${props => props.site_font_color};
            border-radius: 50%;
            width: 29px;
            height: 29px;
        }
    }
`

const VideoDiv = styled.div`
    width: 100%;
    position: relative;
    cursor: pointer;
    border-radius: 5px;

    video{
        max-width: 210px;
        object-fit: cover;
        border-radius: 5px;
    }
    
    .progress {
        border-radius: 5px;
    }
    
    @media (max-width:767px) {
        max-width: 80%;
        display: inline-block;
        video{
            max-width: 100%;
        }

        .progress {
            width: 82%;
            display: inline-block !important;
            border-radius: 5px;
        }
    }
`

const CloseButton = styled.button`
    right: 5px;
    top: 0;
    position: absolute;
    border-radius: 82%;
    width: 24px;
    background-color: ${props => props.content_color} !important;
    color: ${props => props.content_font_color} !important;
    cursor: pointer;

    :before {
        font-size: medium;
    }

    @media(max-width: 767px) {
        right: ${props => props.isRearrangeModeEnable === true ? '20%' : '13%'};
    }
`

const OverLayDiv = styled.div`
    width: 100%;
    height: ${props => props.type === 'video' ? '150px' : '100%'};
    max-width: ${props => props.type === 'video' ? '210px' : '100%'};
    position: absolute;
    background-color: #FFFFFF4F;
    z-index: 4;
    padding: 0 20px;
    border-radius: 5px;
    display: ${props => props.isRearrangeModeEnable === true ? 'block' : 'none'};
    left: ${props => props.type === 'video' ? '3px' : '0px'};

    @media (min-width:991px) and (max-width:1280px) {
        left: ${props => props.type === 'video' ? '5px' : '0px'};
        max-width: ${props => props.type === 'video' ? '153px' : '100%'};
    }
    @media (min-width:767px) and (max-width:991px) {
        left: ${props => props.type === 'video' ? '5px' : '0px'};
        max-width: ${props => props.type === 'video' ? '183px' : '100%'};        
    }
    @media (max-width:767px) {
        left: 0px !important;
        max-width: 100% !important;
    }
`

const GalleryIcon = styled.div`
    position: absolute;
    top: calc(45.5%);
    left: calc(47.5%);
    cursor: pointer;

    .video-button-span {
        font-size: 30px;
        color: rgb(211, 211, 211);
        background-color: black;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        position: relative;
        display: inline-block;
    }

    .play-icon {
        font-size: 30px;
        position: absolute;
        border-radius: 51px;
        left: -5px;
        top: -5px;
    }
`

const DivData = styled.div`
    background-image: ${props => props.imageUrl};
    height:150px;
    background-position:center;
    background-repeat:no-repeat;
    background-size: cover;
    background-color: #000000;
    position: relative;
    cursor: pointer;
    border-radius: 5px;
    width: inherit;

    @media (max-width:767px) {
        max-width: 80%;
        display: inline-block !important;
        margin-bottom: 1%;
    }
`

const PlusButton = styled.label`
    background-color: ${props => props.content_color};
    color: ${props => props.content_font_color};
    height: 100%;
    border-radius: 5px;
    cursor: ${props => props.disabled === false ? 'pointer' : 'inherit'};
    pointer-events: ${props => props.disabled === true ? 'none' : ''};
    display: flex;
    cursor: pointer;
    box-shadow: 0 5px 15px 0 ${props => props.button_shadow_color}44, 0 4px 15px 0 ${props => props.button_shadow_color}44 !important;
`

const RadioButton = styled.div`
    color:${props => props.site_font_color} !important;
    .custom-control-input:checked ~ .custom-control-label::before {
        background-color: ${props => props.site_font_color} !important;
    }
    .custom-control-input:checked~.custom-control-label::before {
        border-color: ${props => props.site_font_color} !important;
    }
`

const HelperButton = styled.button`
    border-radius: 25px;
    border: none;
    font-size: 20px;
    vertical-align: middle;
`

const StyledButton = styled.button`
    border-radius: 0% 0% 0% 0% !important;
    background-color: ${props => props.backgroundColor} !important;
    color: ${props => props.color} !important;
    border-color: ${props => props.borderColor} !important;

    @media (max-width: 767px) {
        .desktop-text {
            position: relative;
            left: 51px;
        }
    }
`

const ButtonGroup = styled.div`
    width:100%;

    button.btn.mb-2.p-2.shadow-none.m-0:hover {
        background-color: ${props => props.content_color};
        color: ${props => props.content_font_color} !important;
        outline: 1px solid ${props => props.content_color} !important;
        box-shadow: 0 5px 15px 0 ${props => props.button_shadow_color}44, 0 4px 15px 0 ${props => props.button_shadow_color}44 !important;
    }

    button.btn.mb-2.p-2.m-0.border-2:focus {
        box-shadow: 0 5px 15px 0 ${props => props.button_shadow_color}44, 0 4px 15px 0 ${props => props.button_shadow_color}44 !important;
    }
    button.btn.selectedOption {
        box-shadow: 0 5px 15px 0 ${props => props.button_shadow_color}44, 0 5px 15px 0 ${props => props.button_shadow_color}44 !important;
    }

    button.btn.mb-2.p-2.shadow-none.m-0:focus-visible, button.btn.mb-2.p-2.shadow-none.m-0:focus {
        outline: transparent !important;
    }

    button.rounded-start {
        border-bottom-left-radius: .25rem !important;
        border-top-left-radius: .25rem !important;
    }
    
    button.rounded-end {
        border-bottom-right-radius: .25rem !important;
        border-top-right-radius: .25rem !important;
    }

    @media (max-width: 1279px) and (min-width: 991px) {
        button.btn.mb-2.p-2.shadow-none.m-0 {
            font-size: 0.7125rem;
        }
    }

    @media (max-width: 767px) {
        flex-direction: column;

        .selectedOption {
            flex-basis: auto;
        }

        button.btn.mb-2.p-2.shadow-none.m-0 {
            display: block;
            width: 100% !important;
            min-height: auto;
        }
    }
`
const MediaPreviewDiv = styled.div`
   textAlign: 'left';
   @media (min-width: 767px) {
        padding-bottom: 6px;
}`


function PreviewContent(props) {
    const { content_color, site_font_color, content_font_color, button_shadow_color } = props.auth.appSettings
    const { isAdmin } = props.auth.user
    const { category } = props.mediaManager
    const [helperModelOpen, setHelperModelOpen] = useState(false)
    const {
        isSelectedFromContentManager,
        mediaPreview,
        media,
        isPreviewModeEnable,
        requestFrom,
        setSelectedCategory,
        selectedCategory
    } = props
    const selectFromMediaManager = isSelectedFromContentManager && ['message', 'mass-message', 'welcome-message'].includes(requestFrom)
    const showMarkAsPreviewButton = _.get(props, 'showMarkAsPreviewButton', true)
    const newFiles = media.filter(obj => obj.selectedFile ? obj.selectedFile.format !== 'modern' : obj.format !== 'modern')

    const getGalleryProgress = (index) => {
        const progress = _.get(props.uploadProgress, 'progress', '')
        const progressIndex = _.get(props.uploadProgress, 'index', '')
        const progressNumber = _.get(props.uploadProgress, 'progressNumber', '')
        if (progress !== '' && progressIndex === index) {
            return <span className='progress-span mt-1'>
                <div className='progress'>
                    <div
                        className='progress-bar progress-bar-striped progress-bar-animated'
                        role='progressbar'
                        style={{ width: progress, backgroundColor: content_color, color: content_font_color }}
                    >{progress}</div>
                </div>
                {props.uploadProgressInMb(progressIndex, progressNumber)}
            </span>
        }
        return <></>
    }

    const previewContent = (name) => {
        const fileArray = name === 'original' ? media : mediaPreview
        return <>
            {
                fileArray.map((item, i) => {
                    let url = ''
                    let isUpdatePreview = false
                    if (item.url === undefined) {
                        const format = _.get(item, 'selectedFile.format', 'classic')
                        const small_thumb = _.get(item, 'selectedFile.small_thumb', '')
                        const file = format === 'modern' ? small_thumb : item.renderFile
                        url = 'url(' + file + ')'
                    } else {
                        const format = _.get(item, 'format', 'classic')
                        const file = format === 'modern' ? item.thumbnail_url : item.url
                        url = 'url(' + file + ')'
                        isUpdatePreview = true
                    }
                    return (
                        <ColumnDiv
                            isUploading={props.isLoading}
                            content_color={content_color}
                            site_font_color={site_font_color}
                            key={i}
                            className={`align-items-center col-12 col-md-4 d-flex justify-content-center mt-3 ${props.isRearrangeModeEnable === false && isPreviewModeEnable ? 'flex-column' : ''}`}
                        >
                            {['video/quicktime', 'video/mp4', 'video'].includes(isUpdatePreview === true ? item.content_type : item.selectedFile.type) ?
                                <>
                                    <CloseButton
                                        className='align-items-center close d-flex justify-content-center mr-4 mt-2'
                                        content_color={content_color}
                                        content_font_color={content_font_color}
                                        type={'video'}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            props.handleDeletePhoto(i, name)
                                        }}
                                        disabled={props.isLoading}
                                        style={{ zIndex: '5' }}
                                        isRearrangeModeEnable={name === 'preview' ? false : props.isRearrangeModeEnable && props.isLoading === false}
                                    />
                                    <VideoDiv
                                        onClick={() => { props.openDialog(isUpdatePreview === true ? item.url : item.renderFile, 'video') }}
                                        draggable={props.isLoading === false && props.isRearrangeModeEnable && name === 'original' ? true : false}
                                        onDragOver={(e) => e.preventDefault()}
                                        onDragStart={() => {
                                            props.handleDrag(i)
                                        }}
                                        onDrop={(e) => {
                                            e.preventDefault()
                                            props.handleDrop(i)
                                        }}
                                    >
                                        <OverLayDiv type='video' isRearrangeModeEnable={props.isRearrangeModeEnable} />
                                        {isIOS ?
                                            <video src={isUpdatePreview === true ? item.url : item.renderFile} poster={getCloudFrontAssetsUrl('images/no-preview-video.png')} id='uploaded-video' style={{ height: '150px', width: '100%', position: 'relative', backgroundColor: 'black' }} />
                                            :
                                            <video src={isUpdatePreview === true ? item.url : item.renderFile} id='uploaded-video' style={{ height: '150px', width: '100%', position: 'relative', backgroundColor: 'black' }} />
                                        }
                                        <GalleryIcon>
                                            <span className='video-button-span'>
                                                <i className='fas fa-play-circle play-icon' />
                                            </span>
                                        </GalleryIcon>
                                    </VideoDiv>
                                    {props.isLoading === false && props.isRearrangeModeEnable === true && name === 'original' &&
                                        <div className='positioning text-dark d-inline'>
                                            <i className='fas fa-arrow-up fa-2x' style={{ display: i === 0 && 'none' }} onClick={(e) => {
                                                e.preventDefault()
                                                props.reArrangeImage(i, i - 1)
                                            }}></i>{i > 0 && <br></br>}
                                            <i className='fas fa-arrow-down fa-2x' style={{ display: i === media.length - 1 && 'none', marginTop: i === 0 ? '0px' : '1rem' }} onClick={(e) => {
                                                e.preventDefault()
                                                props.reArrangeImage(i, i + 1)
                                            }}></i>
                                        </div>
                                    }
                                    {(props.isLoading && ['message', 'mass-message'].includes(requestFrom)) && name === props.uploadProgress.type && getGalleryProgress(i)}
                                    {showMarkAsPreviewButton && name === 'original' && isPreviewModeEnable && !props.isRearrangeModeEnable &&
                                        <div className='form-check mt-2'>
                                            <input className='form-check-input' type='checkbox' checked={i === props.selectedPreviewIndex} name='media_preview' onChange={() => props.onMarkAsPreview(item, i)} id={`media_preview${i}`} disabled={props.isLoading} />
                                            <label className='form-check-label' htmlFor={`media_preview${i}`} style={{ color: site_font_color }}>
                                                Set as Preview
                                            </label>
                                        </div>
                                    }
                                </>
                                :
                                <>
                                    <CloseButton
                                        className='align-items-center close d-flex justify-content-center mr-3 mt-2'
                                        content_color={content_color}
                                        content_font_color={content_font_color}
                                        type={'image'}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            props.handleDeletePhoto(i, name)
                                        }}
                                        disabled={props.isLoading}
                                        style={{ zIndex: '5' }}
                                        isRearrangeModeEnable={name === 'preview' ? false : props.isRearrangeModeEnable && props.isLoading === false}
                                    />
                                    <DivData
                                        imageUrl={url}
                                        onClick={() => { props.openDialog(isUpdatePreview === true ? item.url : item.renderFile, 'photo') }}
                                        draggable={props.isLoading === false && props.isRearrangeModeEnable && name === 'original' ? true : false}
                                        onDragOver={(e) => e.preventDefault()}
                                        onDragStart={() => {
                                            props.handleDrag(i)
                                        }}
                                        onDrop={(e) => {
                                            e.preventDefault()
                                            props.handleDrop(i)
                                        }}
                                    >
                                        <OverLayDiv type='image' isRearrangeModeEnable={props.isRearrangeModeEnable} />
                                    </DivData>
                                    {props.isLoading === false && props.isRearrangeModeEnable && name === 'original' &&
                                        <div className='positioning text-dark d-inline'>
                                            <i className='fas fa-arrow-up fa-2x' style={{ display: i === 0 && 'none' }} onClick={(e) => {
                                                e.preventDefault()
                                                props.reArrangeImage(i, i - 1)
                                            }}></i>{i > 0 && <br></br>}
                                            <i className='fas fa-arrow-down fa-2x' style={{ display: i === media.length - 1 && 'none', marginTop: i === 0 ? '0px' : '1rem' }} onClick={(e) => {
                                                e.preventDefault()
                                                props.reArrangeImage(i, i + 1)
                                            }}></i>
                                        </div>
                                    }
                                    {props.isLoading && ['message', 'mass-message'].includes(requestFrom) && name === props.uploadProgress.type && getGalleryProgress(i)}
                                    {showMarkAsPreviewButton && name === 'original' && isPreviewModeEnable && !props.isRearrangeModeEnable &&
                                        <div className='form-check mt-2'>
                                            <input className='form-check-input' type='checkbox' checked={i === props.selectedPreviewIndex} name='media_preview' onChange={() => props.onMarkAsPreview(item, i)} id={`media_preview${i}`} disabled={props.isLoading} />
                                            <label className='form-check-label' htmlFor={`media_preview${i}`} style={{ color: site_font_color }}>
                                                Set as Preview
                                            </label>
                                        </div>
                                    }
                                </>
                            }
                        </ColumnDiv>
                    )
                })
            }
        </>
    }

    const getPlusButton = (name) => {
        let id = `button-gallery-${name}`
        return <>
            <div className='col-12 col-md-4 mt-3 mb-5'
                style={{
                    height: '150px',
                    marginBottom: '3%',
                    margin: ((name === 'original' && media.length === 0) || (name !== 'original' && mediaPreview.length === 0)) ? 'auto' : '0%',
                    maxWidth: '150px',
                    outlineColor: 'transparent'
                }}
                name={name}
            >
                <PlusButton
                    htmlFor={id}
                    content_color={content_color}
                    fullWidth={true}
                    className='align-items-center justify-content-center'
                    content_font_color={content_font_color}
                    disabled={props.isLoading}
                    name={name}
                    button_shadow_color={button_shadow_color}
                    onClick={() => {
                        if (isSelectedFromContentManager) {
                            if (name === 'original') {
                                props.setShowMediaSelectionPopup(true)
                            } else {
                                props.setShowMediaPreviewSelectionPopup(true)
                            }
                        }
                    }}
                >
                    {!selectFromMediaManager && requestFrom !== 'resend-mass-message' &&
                        <input
                            accept='image/png,image/jpg,image/jpeg,video/mp4,video/quicktime'
                            id={id}
                            onChange={(e) => props.handleGalleryChange(e, name)}
                            style={{ display: 'none' }}
                            type='file'
                            disabled={props.isLoading}
                            multiple={isAdmin === true ? true : false}
                            name={name}
                        />
                    }
                    <i className='fas fa-plus' aria-hidden='true'></i>
                </PlusButton>
            </div>
        </>
    }

    const contentManagerTab = () => {
        return <>
            <span>Select Media from:</span>
            <ButtonGroup
                className='button-group d-flex mt-2 mb-4 flex-row'
                content_color={content_color}
                content_font_color={content_font_color}
                button_shadow_color={button_shadow_color}
            >
                <StyledButton type='button'
                    className={classNames('btn mb-2 p-2 m-0 rounded-start shadow-none w-100',
                        {
                            'border-2 selectedOption': isSelectedFromContentManager,
                            'shadow-none': !isSelectedFromContentManager
                        }
                    )}
                    disabled={props.isLoading}
                    borderColor={!isSelectedFromContentManager && content_color}
                    onClick={() => { props.setIsSelectedContentManager(true) }}
                    backgroundColor={isSelectedFromContentManager && content_color}
                    color={isSelectedFromContentManager ? content_font_color : site_font_color}
                >
                    <i className='d-block d-md-none fas fa-photo-video'></i>
                    <span className='d-none d-md-block desktop-text'>CONTENT MANAGER</span>
                </StyledButton>
                <StyledButton type='button'
                    className={classNames('btn mb-2 p-2 m-0 rounded-end shadow-none w-100',
                        {
                            'border-2 selectedOption': !isSelectedFromContentManager,
                            'shadow-none': isSelectedFromContentManager
                        }
                    )}
                    onClick={() => { props.setIsSelectedContentManager(false) }}
                    disabled={props.isLoading}
                    backgroundColor={!isSelectedFromContentManager && content_color}
                    color={!isSelectedFromContentManager ? content_font_color : site_font_color}
                    borderColor={isSelectedFromContentManager && content_color}
                >
                    <i className='d-block d-md-none fas fa-upload'></i>
                    <span className='d-none d-md-block'>UPLOAD NEW MEDIA</span>
                </StyledButton>
            </ButtonGroup>
        </>
    }

    const getAllCategory = async () => {
        if (isAdmin === true && category.length === 0) {
            await props.getAllCategory()
        }
    }

    useEffect(() => {
        getAllCategory()
    }, [])

    return (
        <>
            <div className='mt-2 mb-2'>
                {requestFrom === 'mass-message' && contentManagerTab()}
                <Dropzone multiple={false} onDrop={props.galleryFilesFromOriginal} noDrag={!isSelectedFromContentManager ? props.isRearrangeModeEnable : true}>
                    {({ getRootProps }) => (
                        <DropZoneSection content_color={content_color} site_font_color={site_font_color}>
                            <div {...getRootProps()} style={{ height: '100%', textAlign: 'center', color: '#ECECEC', outline: '0px' }}>
                                <h4 style={{ color: site_font_color, marginBottom: '0%' }}>{isSelectedFromContentManager ? 'Choose From Media Manager' : 'Upload Media'}</h4>
                                <div className='row d-flex justify-content-center'>
                                    {previewContent('original')}
                                    {!props.isRearrangeModeEnable && isAdmin === true && <>
                                        {getPlusButton('original')}
                                    </>}
                                    {media.length === 0 && isAdmin === false && <>
                                        {getPlusButton('original')}
                                    </>}
                                </div>
                            </div>
                            <div className='row'>
                                <MediaPreviewDiv className='col-12 col-md-6 px-5'>
                                    {media.length > 1 &&
                                        <RadioButton content_color={content_color} site_font_color={site_font_color} style={{ textAlign: 'left' }}>
                                            <div className='custom-control custom-switch'>
                                                <input
                                                    type='checkbox'
                                                    className='custom-control-input'
                                                    id='REORDER_MODE'
                                                    name='ReorderMode'
                                                    onChange={() => { props.setIsRearrangeModeEnable(!props.isRearrangeModeEnable) }}
                                                    checked={props.isRearrangeModeEnable}
                                                    disabled={props.isLoading}
                                                />
                                                <label className='custom-control-label' htmlFor='REORDER_MODE'>Reorder</label>
                                            </div>
                                        </RadioButton>
                                    }
                                    {props.option === 'locked' &&
                                        <RadioButton content_color={content_color} site_font_color={site_font_color} style={{ textAlign: 'left' }}>
                                            <div className='custom-control custom-switch'>
                                                <input
                                                    type='checkbox'
                                                    className='custom-control-input'
                                                    id='PREVIEW_MODE_REUSE'
                                                    name='PreviewMode'
                                                    onChange={props.onChangePreviewMode}
                                                    checked={isPreviewModeEnable}
                                                    disabled={props.isLoading}
                                                />
                                                <label className='custom-control-label' htmlFor='PREVIEW_MODE_REUSE'>Add Preview</label>
                                            </div>
                                            <HelperButton className='mx-1'
                                                disabled={props.isLoading}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    setHelperModelOpen(true)
                                                }} style={{ backgroundColor: 'transparent', color: content_color }}>
                                                <i className='fas fa-question-circle'></i>
                                            </HelperButton>
                                        </RadioButton>
                                    }
                                </MediaPreviewDiv>
                                {(isAdmin === true && newFiles.length > 0) &&
                                    <div className={`col-12 col-md-6 mt-3 mt-md-0 ${props.option === 'locked' ? 'ml-md-0' : 'ml-md-auto'}`}>
                                        <MediaCategory
                                            selectedCategory={selectedCategory}
                                            setSelectedCategory={setSelectedCategory}
                                            loading={props.isLoading}
                                        />
                                    </div>
                                }
                            </div>
                        </DropZoneSection>
                    )}
                </Dropzone>
            </div>
            {isPreviewModeEnable &&
                <div className='mt-2 mb-2'>
                    <Dropzone multiple={true} onDrop={props.galleryFilesFromPreview}>
                        {({ getRootProps }) => (
                            <DropZoneSection content_color={content_color} site_font_color={site_font_color}>
                                <div {...getRootProps()} style={{ height: '100%', textAlign: 'center', color: '#ECECEC', outline: '0px' }}>
                                    <h4 style={{ color: site_font_color, marginBottom: '0%' }}>Preview</h4>
                                    <div className='row d-flex justify-content-center'>
                                        {previewContent('preview')}
                                        {mediaPreview.length === 0 &&
                                            getPlusButton('preview')
                                        }
                                    </div>
                                </div>
                            </DropZoneSection>
                        )}
                    </Dropzone>
                </div>
            }
            {helperModelOpen === true &&
                <HelperModelForPreview setHelperModelOpen={setHelperModelOpen} />
            }
        </>
    )
}

PreviewContent.propTypes = {
    auth: PropTypes.object.isRequired,
    media: PropTypes.array.isRequired,
    mediaPreview: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    handleDeletePhoto: PropTypes.func.isRequired,
    handleDrag: PropTypes.func.isRequired,
    handleDrop: PropTypes.func.isRequired,
    reArrangeImage: PropTypes.func.isRequired,
    uploadProgress: PropTypes.object,
    uploadProgressInMb: PropTypes.func,
    handleGalleryChange: PropTypes.func.isRequired,
    option: PropTypes.string.isRequired,
    onChangePreviewMode: PropTypes.func.isRequired,
    isPreviewModeEnable: PropTypes.bool.isRequired,
    galleryFilesFromPreview: PropTypes.func.isRequired,
    openDialog: PropTypes.func.isRequired,
    galleryFilesFromOriginal: PropTypes.func.isRequired,
    selectedPreviewIndex: PropTypes.number,
    onMarkAsPreview: PropTypes.func,
    showMarkAsPreviewButton: PropTypes.bool.isRequired,
    isSelectedFromContentManager: PropTypes.bool,
    setShowMediaSelectionPopup: PropTypes.func,
    setShowMediaPreviewSelectionPopup: PropTypes.func,
    isRearrangeModeEnable: PropTypes.bool.isRequired,
    setIsRearrangeModeEnable: PropTypes.func.isRequired,
    setIsSelectedContentManager: PropTypes.func,
    requestFrom: PropTypes.string,
    selectedCategory: PropTypes.object.isRequired,
    setSelectedCategory: PropTypes.func.isRequired,
    mediaManager: PropTypes.object,
    getAllCategory: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    mediaManager: state.media
})

export default connect(
    mapStateToProps, { getAllCategory }
)(withRouter((PreviewContent)))
