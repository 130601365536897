import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import BootstrapNavbar from './BootstrapNavbar'
import FooterPage from './FooterLayout'
import AgeModalPage from './AgeModal'
import NewPaymentModal from './NewPaymentModal'
import CopyToClipboardToast from './CopyToClipboardToast'
import _ from 'lodash'
import MaintenancePage from './../../components/maintenance/Maintenance'
import WebsiteFontColor from '../Layouts/WebsiteFontColor'
import SweetAlertsWrapper from './../../components/Layouts/SweetAlertsWrapper'
import MiscellaneousPopUp from '../Layouts/MiscellaneousPopUp'
import MigrationSubscriptionModal from './MigrationSubscriptionModal'
import PaymentForm from '../payment/PaymentForm'
import RebillSubscriptionPopup from '../payment/RebillSubscriptionPopup'
import Cookies from 'universal-cookie'
import AnnouncementBanner from './AnnouncementBanner'
import RebillNotificationPopup from './RebillNotificationPopup'
import Loader from './Loader'
import ProgressiveWebPopUp from './ProgressiveWebPopUp'
import BottomNavbar from './BottomNavbar'
import styled from 'styled-components'
import { size } from '../../utils/common'
import WebPushNotificationPrompt from './WebPushNotificationPrompt'
import { isIOS } from 'react-device-detect'
import UniversalLoginDifferentPasswordPopup from '../universal-login/UniversalLoginDifferentPasswordPopup'
import OuterStreamWrapper from '../live-stream/OuterStreamWrapper'
import ConfirmSweetAlert from '../Layouts/ConfirmSweetAlert'
import { setAlertLoader, setShowAlertOnPageWrapper } from '../../actions/sweetAlertActions'
import {
    unlockContentDetail,
    setShowAddFundPopup,
    setUpdatedBalance,
    setIsChatScreenOpen,
    setChatId,
    setWebsiteId,
    setSelectedUserId,
    deleteSingleResendMassMessage
} from '../../actions/chatActions'
import AddFundForm from '../crypto/AddFundForm'
import socket from '../../utils/socket'
import { ROLE_MODEL, ROLE_CONTENT_MANAGER, ROLE_USER } from '../../utils/constant'
import {
    updateUsersLastMessage,
    addNewMessageInEnd,
    readMessage,
    updateUnreadMessageCountInUserList,
    getUserById
} from '../../actions/chatActions'
import { updateUnreadCountInNavbar, updateModelTipsUnreadCount } from '../../actions/adminActions'

const one_year_in_seconds = 60 * 60 * 24 * 365

const StyledDiv = styled.div`
    @media (min-width: ${size.mobileS}) and (max-width: ${size.tabletL}) {
        padding-bottom:${isIOS && window.matchMedia('(display-mode: standalone)').matches ? '90px' : '60px'};
    }
    
    @media (max-width: 320px) {
        padding-bottom:60px;
    }
`

const Modal = styled.div`
    .modal {
        display: block;
        background-color: #00000080;
    }

    .modal-content {
        background-color: ${props => props.cardBackgroundColor};
    }

    .close {
        color: ${props => props.siteFontColor};
        opacity: 1;
    }

    .close:hover {
        color: ${props => props.siteFontColor};
        opacity: 1 !important;
    }
`

class PageWrapper extends Component {
    constructor() {
        super()
        this.handleMessageReceive = this.handleMessageReceive.bind(this)
        this.addMessage = this.addMessage.bind(this)
        this.scrollToBottom = this.scrollToBottom.bind(this)
        this.checkIfUserExistsInUserList = this.checkIfUserExistsInUserList.bind(this)
        this.onlineUserList = this.onlineUserList.bind(this)
        this.updateMessageUnreadCount = this.updateMessageUnreadCount.bind(this)
        this.state = {
            isMobileDevice: window.innerWidth <= 991
        }
    }

    getUTMParams(url) {
        const query = new URLSearchParams(url)
        const utm_params = {}
        let url_has_utm_params = false

        const parameters = ['source', 'medium', 'campaign', 'term', 'content']
        parameters.forEach((item) => {
            const value = query.get(`utm_${item}`)
            if (value !== null) {
                url_has_utm_params = true
                const key = `utm_${item}`
                utm_params[key] = value
            }
        })

        if (url_has_utm_params === true) {
            const cookies = new Cookies()
            cookies.set('pcp_utm_params', JSON.stringify(utm_params), { maxAge: Date.now() + one_year_in_seconds })
        }
    }

    componentDidMount() {
        // Update the last message in real-time when the admin sends a mass message to all users.
        if (this.props.auth.user.isAdmin) {
            socket.on('UPDATE_USER_LAST_MESSAGE', (data) => {
                const lastMessage = data.message
                this.props.updateUsersLastMessage({
                    user_id: data.receiverId,
                    message: lastMessage,
                    type: data.type,
                    isAdmin: data.fromAdmin,
                    isMassMessage: true
                })
            })
        }

        window.addEventListener('resize', this.handleResize)

        const { userList, isChatScreenOpen } = this.props.chat

        if (userList.length === 1 && !window.location.pathname.includes('/private-chat/')) {
            // Do nothing
            this.props.setShowAlertOnPageWrapper(false)
            this.props.setIsChatScreenOpen(false)
            if (this.state.isMobileDevice === true) {
                this.props.setChatId('')
                this.props.setWebsiteId('')
            }
        } else if (isChatScreenOpen === true && this.props.match.params.id === undefined && !this.props.auth.user.isAdmin && userList.length !== 0) {
            this.props.setShowAlertOnPageWrapper(false)
            this.props.setIsChatScreenOpen(false)
            if (this.state.isMobileDevice === true) {
                this.props.setChatId('')
                this.props.setWebsiteId('')
            }
        }

        if (isChatScreenOpen === true && this.props.match.params.id === undefined && this.props.auth.user.isAdmin) {
            if (this.state.isMobileDevice === true) {
                this.props.setSelectedUserId('', this.props.history)
            }
            this.props.setIsChatScreenOpen(false)
        }
        this.onlineUserList()

        // get the online user list while admin open the chat page
        if (this.props.auth.user.isAdmin) {
            socket.emit('GET_ONLINE_USER_LIST')
            socket.emit('ADMIN_IN_USER_CHAT', { adminId: this.props.auth.user._id, userId: this.props.chat.selectedUserId })
        }

        socket.on('MESSAGE_RECEIVE', (msg) => {
            this.handleMessageReceive(msg)
        })

        const live_script_url = 'https://api.forumpay.com/pay/events/payment.js'
        const sandbox_script_url = 'https://sandbox.api.forumpay.com/pay/events/payment.js'
        const { is_crypto_payment_enabled, enable_forumpay_payment_live_mode } = this.props.auth.appSettings

        if (is_crypto_payment_enabled === false) {
            return
        }

        const script = document.createElement('script')
        script.src = enable_forumpay_payment_live_mode === true ? live_script_url : sandbox_script_url
        document.body.appendChild(script)
    }

    handleResize = () => {
        this.setState({ isMobileDevice: window.innerWidth <= 991 })
    }

    onlineUserList() {
        socket.on('ONLINE_USER_LIST', (users) => {
            if (users.length > 1) {
                for (const user of users) {
                    if (user !== '' && user !== null) {
                        const roomData = {
                            roomId: user,
                            userId: this.props.auth.user._id
                        }
                        socket.emit('JOIN_ROOM', roomData)
                    }
                }
            } else {
                if (users !== '' && users !== null) {
                    const roomData = {
                        roomId: users,
                        userId: this.props.auth.user._id
                    }
                    // add the user in the admin chat room
                    socket.emit('JOIN_ROOM', roomData)
                }
            }
        })
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
        // Cleanup socket event listeners when the component is unmounted
        socket.off('MESSAGE_RECEIVE')
        socket.off('ONLINE_USER_LIST')
    }

    handleMessageReceive(msg) {
        const userIdString = this.props.auth.user._id.toString()
        const { receiverId, senderId } = msg
        const receiverIdString = receiverId.toString()
        const { selectedUserId, selectedModelId } = this.props.chat
        const userId = this.props.auth.isAdmin ? (msg.fromAdmin === true ? receiverId : senderId) : (msg.fromAdmin === true ? senderId : receiverId)
        const isAdmin = this.props.auth.user.isAdmin

        let isUserExist = true
        if (isAdmin === true) {
            // check sender user exist in current chat user list
            isUserExist = this.props.chat.userList.some(user => user._id === userId)
        }

        let data = { userId: selectedUserId }
        if (isAdmin === true) {
            data.modelId = this.props.auth.appSettings.model_id || selectedModelId
        }

        if (isUserExist === false && this.props.chat.userList.length > 0) {
            // find current user details and update in the user list
            this.props.getUserById(userId)
        }

        let shouldAddMessageInStore = false
        if (isAdmin && msg.isMassMessage) {
            shouldAddMessageInStore = true
        } else if (isAdmin === true && msg.type !== 'text') {
            shouldAddMessageInStore = receiverIdString === selectedUserId
        } else if (msg.fromAdmin === true) {
            shouldAddMessageInStore = receiverIdString === userIdString
        }

        let isSameDomain = false
        if (isAdmin === false && this.props.chat.userList.length > 0) {
            const userDomain = this.props.chat.userList.find(user => user._id === selectedUserId)
            if (userDomain !== undefined) {
                isSameDomain = userDomain.domain === window.location.hostname
            }
        }

        const senderUserId = (this.props.chat.userList.length === 1 || msg.type === 'text' || isSameDomain) ? senderId : msg.userID
        if (shouldAddMessageInStore === false && msg.fromAdmin === false && (this.props.auth.user._id === senderUserId || isAdmin === true)) {
            // check user need to add message in list while message send by user
            const id = isAdmin ? senderId : selectedModelId
            shouldAddMessageInStore = this.checkIfUserExistsInUserList(id)
        }

        if (shouldAddMessageInStore) {
            const processedMessages = new Set()
            if (msg.isResend === true && !processedMessages.has(msg.udid)) {
                processedMessages.add(msg.udid)
                this.props.deleteSingleResendMassMessage(userId, msg.udid)
            }
            this.addMessage(msg, userId)

            const lastMessage = msg.type === 'tips'
                ? `<user_name> just tipped you ${msg.message}!`
                : msg.type === 'GO_LIVE_STREAM' && msg.message === '' ? 'Go Live Stream with <user_name> has ended.' : msg.message
            const isMassMessage = _.get(msg, 'isMassMessage', false)
            if (isAdmin && msg.type !== 'system') {

                this.props.updateUsersLastMessage({
                    user_id: userId,
                    message: lastMessage,
                    type: msg.type,
                    isAdmin: msg.fromAdmin,
                    isMassMessage: isMassMessage
                })
            }

            if (isAdmin === false && msg.type !== 'system' && msg.type !== 'GO_LIVE_STREAM') {
                this.props.updateUsersLastMessage({
                    user_id: userId,
                    message: lastMessage,
                    type: msg.type,
                    isAdmin: msg.fromAdmin,
                    isMassMessage: isMassMessage,
                    domain: window.location.hostname,
                    admin: false
                })
            }

        }
        this.updateMessageUnreadCount(msg, shouldAddMessageInStore, data, userId)
    }

    updateMessageUnreadCount(msg, shouldAddMessageInStore, data, userId) {
        const isAdmin = this.props.auth.user.isAdmin
        const modelId = this.props.auth.appSettings.model_id
        const { receiverId, senderId } = msg
        const receiverIdString = receiverId.toString()
        const senderIdString = senderId.toString()
        const { selectedUserId } = this.props.chat

        if (msg.isMassMessage === false || (msg.isMassMessage && shouldAddMessageInStore)) {
            let shouldReadMessage = false
            if (isAdmin && [receiverIdString, senderIdString].includes(selectedUserId)) {
                shouldReadMessage = true
            }
            if (isAdmin === false && [receiverIdString, senderIdString].includes(selectedUserId)) {
                shouldReadMessage = true
            }
            const role = _.get(this, 'props.auth.user.role', ROLE_USER)
            if ([ROLE_MODEL, ROLE_CONTENT_MANAGER, ROLE_USER].includes(role) && shouldReadMessage === true && window.location.pathname.includes('/private-chat')) {
                data.email = this.props.auth.user.email
                this.props.readMessage(data, isAdmin)
            } else if (role === ROLE_USER || senderIdString !== modelId) {
                if (msg.tipFrom !== 'menu' || isAdmin === true) {
                    this.props.updateUnreadMessageCountInUserList(userId, isAdmin)
                    if (msg.type === 'tips' && msg.fromAdmin === false) {
                        this.props.updateModelTipsUnreadCount(1)
                    }
                    if (role === ROLE_USER && shouldAddMessageInStore === false) return
                    this.props.updateUnreadCountInNavbar(1)
                }
            }
        }
    }

    addMessage(message, selectedUserId) {
        if (message && !message.isRotated) {
            this.props.addNewMessageInEnd(message, selectedUserId)
            if (selectedUserId == this.props.chat.selectedUserId) {
                setTimeout(() => { this.scrollToBottom() }, 0)
            }
        }
    }

    checkIfUserExistsInUserList(userId) {
        let isExistUser = false
        let userIndex = -1
        if (this.props.chat.userList !== undefined) {
            userIndex = this.props.chat.userList.findIndex(obj => obj._id === userId)
        }
        if (userIndex !== -1 || userId === this.props.auth.user._id) {
            isExistUser = true
        }
        return isExistUser
    }

    scrollToBottom() {
        // get the message-list container and set the scrollTop to the height of the container
        const objDiv = document.getElementById('message-list')
        if (objDiv) {
            objDiv.scrollTop = objDiv.scrollHeight
        }
    }

    setSweetAlertConfirmData() {
        switch (this.props.chat.unlockPaymentData.type) {
            case 'unlock':
                // Do something
                this.props.unlockContentDetail({
                    messageId: this.props.chat.unlockPaymentData.messageId,
                    isUnlockPayment: true,
                    type: 'unlock'
                })
                break
            case 'pay_per_message':
                // Do something
                this.props.unlockContentDetail({
                    isPayPerMessage: true,
                    type: 'pay_per_message'
                })
                break
            case 'pay_per_message_media':
                // Do something
                this.props.unlockContentDetail({
                    isPayPerMessageMedia: true,
                    type: 'pay_per_message_media'
                })
                break
            case 'tips_from_chat':
                // Do something
                this.props.unlockContentDetail({
                    isTipFromChat: true,
                    type: 'tips_from_chat'
                })
                break
            case 'add_funds_for_pay_per_message_text':
                this.props.unlockContentDetail({
                    addFundsForPayPerMessageText: true,
                    type: 'add_funds_for_pay_per_message_text'
                })
                break
            case 'add_funds_for_pay_per_message_media':
                this.props.unlockContentDetail({
                    addFundsForPayPerMessageMedia: true,
                    type: 'add_funds_for_pay_per_message_media'
                })
                break
            default:
                break
        }
    }

    componentDidUpdate() {
        if (this.state.isMobileDevice === true) {
            const { userList, isChatScreenOpen } = this.props.chat

            if (userList.length === 1) {
                // Do nothing
            } else if (isChatScreenOpen === true && this.props.match.params.id === undefined && !this.props.auth.user.isAdmin && userList.length !== 0) {
                this.props.setShowAlertOnPageWrapper(false)
                this.props.setIsChatScreenOpen(false)
                if (this.state.isMobileDevice === true) {
                    this.props.setChatId('')
                    this.props.setWebsiteId('')
                }
            }

            if (isChatScreenOpen === true && this.props.match.params.id === undefined && this.props.auth.user.isAdmin) {
                if (this.state.isMobileDevice === true) {
                    this.props.setSelectedUserId('', this.props.history)
                }
                this.props.setIsChatScreenOpen(false)
            }
        }
    }

    render() {
        this.getUTMParams(this.props.location.search)
        const pathName = window.location.pathname

        const {
            maintenance_mode,
            payment_api_version,
            enable_ccbill_rest_api,
            is_universal_login_enabled,
            card_background_color,
            site_font_color
        } = this.props.auth.appSettings
        const { showAlert, showConfirmAlertInPageWrapper } = this.props.sweetAlert
        const { setNotificationPrompt } = this.props.pushNotification
        // eslint-disable-next-line no-undef
        const enableMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === 'true' || maintenance_mode === true
        const toastType = this.props.copyToClipboard.toastType
        const { isAdmin, isRebillFailed, logout_user_loader, role } = this.props.auth.user
        let { showDifferentPasswordSitePopup } = this.props.universalLogin

        let showProgressiveWebPopUp = true
        if (navigator.standalone || window.matchMedia('(display-mode: standalone)').matches) {
            showProgressiveWebPopUp = false
        }

        const differentPasswordPopupReminderCount = localStorage.getItem('differentPasswordPopupReminderCount')
        if (differentPasswordPopupReminderCount && [0, 12, 23, 31, 45].includes(Number(differentPasswordPopupReminderCount))) {
            showDifferentPasswordSitePopup = true
        }

        const currentRoute = pathName.split('/')
        // Don't show popup on change-password screen
        if (currentRoute[currentRoute.length - 2] === 'change-password') showDifferentPasswordSitePopup = false

        const showFooter = _.get(this.props, 'showFooter', true)

        const { isLiveStreamStarted, streamJoined, isLive } = this.props.liveStream

        if (pathName !== '/login' && (enableMaintenanceMode && (isAdmin === false || isAdmin === undefined)) && _.isEmpty(localStorage.getItem('dev'))) {
            return (
                <WebsiteFontColor>
                    <MaintenancePage />
                </WebsiteFontColor>
            )
        } else {
            return (
                <>
                    {logout_user_loader ?
                        <div className='d-flex flex-column min-vh-100 justify-content-center align-items-center'>
                            <Loader loading={logout_user_loader} size={10} />
                        </div>
                        :
                        <>
                            {(is_universal_login_enabled &&
                                isAdmin === false &&
                                showDifferentPasswordSitePopup)
                                && <UniversalLoginDifferentPasswordPopup />
                            }
                            {!pathName.includes('/private-chat') &&
                                <AnnouncementBanner />
                            }
                            <RebillNotificationPopup />
                            <BootstrapNavbar />
                            <StyledDiv id='RemovePadding'>
                                {this.props.children}
                            </StyledDiv>
                            {((pathName.includes('/private-chat')) || !showFooter) ? <></> : <FooterPage />}
                            <AgeModalPage />
                            <NewPaymentModal />
                            <MigrationSubscriptionModal />
                            {toastType === 'copy' &&
                                <CopyToClipboardToast toastContent={'Copied to clipboard.'} />
                            }
                            <MiscellaneousPopUp />
                            <SweetAlertsWrapper />
                            {this.props.utility.showPaymentModel === true &&
                                <PaymentForm
                                    addCardAndSubscription={false}
                                />
                            }
                            {
                                (isRebillFailed === true && isAdmin === false && (payment_api_version === 'v2' || (payment_api_version === 'v1' && enable_ccbill_rest_api === true))) &&
                                <RebillSubscriptionPopup />
                            }
                            {(showProgressiveWebPopUp) && <ProgressiveWebPopUp />}
                            <BottomNavbar />
                            {(setNotificationPrompt && showAlert === false && role !== 'live_stream_manager') && <WebPushNotificationPrompt />}
                            {(isLiveStreamStarted || (!this.props.auth.user.isAdmin && isLive && !streamJoined)) && <OuterStreamWrapper />}
                            {showConfirmAlertInPageWrapper &&
                                <ConfirmSweetAlert
                                    onConfirm={() => {
                                        this.props.setAlertLoader(true)
                                        this.setSweetAlertConfirmData()
                                    }}
                                    onCancel={() => this.props.setShowAlertOnPageWrapper(false)}
                                />
                            }
                            {this.props.chat.showAddFundPopup &&
                                <Modal cardBackgroundColor={card_background_color} siteFontColor={site_font_color}>
                                    <div className='modal fade show' role='dialog'>
                                        <div id='add-fund-form' className='modal-dialog modal-dialog-centered modal-dialog-scrollable' role='document'>
                                            <div className='modal-content'>
                                                <div className='modal-header align-items-center'>
                                                    <button className='close' onClick={() => {
                                                        this.props.setShowAddFundPopup(false)
                                                        this.props.setShowAlertOnPageWrapper(false)
                                                    }} />
                                                </div>
                                                <div className='modal-body'>
                                                    <div className='container'>
                                                        <AddFundForm
                                                            onHideAddFund={() => { this.props.setShowAddFundPopup(false) }}
                                                            type='chat'
                                                            transactionAmount={Number(this.props.chat.unlockData.amount)}
                                                            remainAmount={this.props.chat.remainAmount}
                                                            onCompleteTransaction={
                                                                (updatedBalance) => {
                                                                    this.props.setShowAddFundPopup(false)
                                                                    if (updatedBalance) {
                                                                        this.props.setUpdatedBalance({ updatedBalance: updatedBalance, isUpdateBalance: true })
                                                                    }
                                                                }
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                            }
                        </>
                    }
                </>
            )
        }
    }
}

PageWrapper.propTypes = {
    auth: PropTypes.object,
    children: PropTypes.node,
    utility: PropTypes.object,
    location: PropTypes.object,
    copyToClipboard: PropTypes.object,
    sweetAlert: PropTypes.object,
    pushNotification: PropTypes.object,
    universalLogin: PropTypes.object,
    showFooter: PropTypes.bool,
    liveStream: PropTypes.object,
    setShowAlertOnPageWrapper: PropTypes.func,
    setAlertLoader: PropTypes.func,
    chat: PropTypes.func,
    unlockContentDetail: PropTypes.func,
    setShowAddFundPopup: PropTypes.func,
    setUpdatedBalance: PropTypes.func,
    updateUsersLastMessage: PropTypes.func,
    addNewMessageInEnd: PropTypes.func,
    readMessage: PropTypes.func,
    updateUnreadMessageCountInUserList: PropTypes.func,
    updateUnreadCountInNavbar: PropTypes.func,
    getUserById: PropTypes.func,
    updateModelTipsUnreadCount: PropTypes.func,
    setIsChatScreenOpen: PropTypes.func.isRequired,
    setChatId: PropTypes.func.isRequired,
    setWebsiteId: PropTypes.func.isRequired,
    setSelectedUserId: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    deleteSingleResendMassMessage: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    copyToClipboard: state.copyToClipboard,
    utility: state.utility,
    sweetAlert: state.sweetAlert,
    pushNotification: state.pushNotification,
    universalLogin: state.universalLogin,
    liveStream: state.liveStream,
    chat: state.chat
})

export default withRouter(connect(mapStateToProps, {
    setShowAlertOnPageWrapper,
    setAlertLoader,
    unlockContentDetail,
    setShowAddFundPopup,
    setUpdatedBalance,
    updateUsersLastMessage,
    addNewMessageInEnd,
    readMessage,
    updateUnreadMessageCountInUserList,
    updateUnreadCountInNavbar,
    getUserById,
    updateModelTipsUnreadCount,
    setIsChatScreenOpen,
    setChatId,
    setWebsiteId,
    setSelectedUserId,
    deleteSingleResendMassMessage
})(PageWrapper))
